// 大小雙 swiper
var swiper = new Swiper(".mySwiper", {
  loop: true,
  loopedSlides: 10,
  spaceBetween: 10,
  slideToClickedSlide: true,
  slidesPerView: 3,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  breakpoints: {
      1024: {
          slidesPerView: 4,
          spaceBetween: 30
      },
      768: {
          slidesPerView: 3,
          spaceBetween: 30
      },
      480: {
          slidesPerView: 3,
          spaceBetween: 10
      }
  }
});
var swiper2 = new Swiper(".mySwiper2", {
  loop: true,
  loopedSlides: 10,
  spaceBetween: 10,
  slidesPerView: 1,
  autoHeight: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    1024: {
      autoHeight: false,
    },
    768: {
      autoHeight: true,
    },
    480: {
      autoHeight: true,

    }
  }

  // thumbs: {
  //   swiper: swiper,
  // },
});
swiper.controller.control = swiper2;
swiper2.controller.control = swiper;
